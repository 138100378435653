import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";
import Home from "./containers/Home";
import Devices from "./containers/Devices";
import Users from "./containers/Users";
import NotFound from "./containers/NotFound";

export default (companyID) =>
<Switch >


    <Route exact path = "/" render = {
        (props) => < Home {...props} extra = { companyID } />
    }
    />
    <Route exact path = "/devices" render = {
        (props) => < Devices {...props} extra = { companyID } />
    }
    />   
    <Route exact path = "/users" render = {
        (props) => < Users {...props} extra = { companyID } />
    }
    />     

<Route component = { NotFound } />
< / Switch >