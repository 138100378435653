import React from "react";
import { SignIn } from "aws-amplify-react";

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  showComponent(theme) {
    return (
      <div className="loginBody">
      <div className="loginContainer">
      <div className="loginTitle"><img src="images/matslogo.png" alt="MATS"/></div>
      <div className="loginForm">
        <form>
          <div className="loginInput">
            <label
              className="inp"
              for="username"
            >
              <input
                className=""
                id="username"
                key="username"
                name="username"
                onChange={this.handleInputChange}
                type="text"
                placeholder="&nbsp;"
              />
              <span className="label">Username</span>
              <span className="border"></span>
             </label>
          </div>

          <div className="loginInput">
            <label
              className="inp"
              for="password"
            >
            <input
              className=""
              id="password"
              key="password"
              name="password"
              onChange={this.handleInputChange}
              type="password"
              placeholder="&nbsp;"
            />
              <span className="label">Password</span>
              <span className="border"></span>            
            </label>
            </div>
            <div>
            <button
              className="effect effect-4"
              type="button"
              onClick={() => super.signIn()}
            >
              Login to MATS
            </button>
            </div>
              <button
                className="loginSmallLink"
                onClick={() => super.changeState("forgotPassword")}
              >
                Reset Password
              </button>
              <button
                className="loginSmallLink"
                onClick={() => super.changeState("signUp")}
              >
                Create account
              </button>              





        </form>
      </div>
      </div>
      </div>
    );
  }
}