import React, {
    Component
} from "react";
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBIcon
} from "mdbreact";

//import Amplify from 'aws-amplify';
import {
    ConfirmSignIn,
    ConfirmSignUp,
    ForgotPassword,
    RequireNewPassword,
    SignUp,
    VerifyContact,
    withAuthenticator
} from 'aws-amplify-react';
//import { withAuthenticator } from 'aws-amplify-react';

import {
    CustomSignIn
} from "./Auth/CustomSignIn";

import {
    Auth,
    API
} from "aws-amplify"

//import { Link } from "react-router-dom";

import Routes from "./Routes";

import "./App.css";



// Hooks
const MenuContext = React.createContext();

class App extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            authState: "",
            isVerified: false,
            userEmail: "",
            userPhone: "",
            companyID: 0,
            isOpen: false,
            loaded: false,
            waitingVerification: true,
            locateMenu: false,
           // roleID: parseInt(this.props.extra.props.role_id),
           roleID: 0,
        };

        Auth.currentAuthenticatedUser().then(result => {
            //console.log("AUTH",result)

            if (result) {
                // Ensure one verification is complete
                if (result.attributes.email_verified || result.attributes.phone_number_verified) {
                    this.setState({
                        isVerified: true
                    })
                    //console.log("Verified Contact Information Found")
                }

                // Check Email
                if (result.attributes.email !== null) {
                    this.setState({
                        userEmail: result.attributes.email
                    })
                    //onsole.log("Email stored", this.state.userEmail)
                }

                // Check Phone
                if (result.attributes.phone_number !== null) {
                    var phone = result.attributes.phone_number

                    var res = phone.replace('+1', '').replace(/\D+/g, '').replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3');

                    this.setState({
                        userPhone: res
                    })
                    //console.log("Phone stored", this.state.userPhone)
                }

                // Get CompanyID
                this.getUser(this.state.userPhone, this.state.userEmail)

            } else {
                this.setState({
                    isVerified: false
                })
                //console.log("No verified contact")
            }
        });



    } // end constructor


    // CHECK USER INFO, Returns companyID if valid
    getUser = async (phone, email) => {
        try {
            let myInit = {
                queryStringParameters: { // OPTIONAL
                    phone: phone,
                    email: email
                }
            }

            let details = await API.get("mats", "/userCheck/", myInit)
            //console.log("USER DETAILS", details)
            if (details.success && details.data.length > 0 && details.data[0].is_active === true) {
                //console.log("USER DETAILS ROLE: ",details.data[0]['role_id'])
                this.setState({
                    companyID: details.data[0],
                    roleID: details.data[0]['role_id']
                })
                // console.log(this.state.companyID)
            } else {
                this.setState({
                    waitingVerification: false
                })
                if (details.err) {
                    console.log(details.err)
                } else {
                    console.log("No valid company returned")
                }
            }

        } catch (err) {
            console.log("error fetching user data.." + err)
        }
    }

    toggleCollapse = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    handleLogout = async event => {
        await Auth.signOut()
        localStorage.clear()
        //this.Router.push('/')
    };

    handleShow = () => {
        console.log("LOCATE")
    }

    render() {
        const {
            locateMenu
        } = this.state

        if (this.props.authState === "signedIn" && this.state.companyID !== 0) {

            return ( <
                MenuContext.Provider value = {
                    {
                        locateMenu
                    }
                } >
                <
                div className = "App" >
                <
                MDBNavbar color = "black"
                dark expand = "md"
                className = "sticky-top" >
                <
                MDBNavbarBrand >
                
                <strong className = "white-text" >
                    <a className="tip" href="/#" tip="v2.10.1">
                        MATS
                    </a>
                < /strong> 

                

                < /
                MDBNavbarBrand > <
                MDBNavbarToggler onClick = {
                    this.toggleCollapse
                }
                /> <
                MDBCollapse id = "navbarCollapse3"
                isOpen = {
                    this.state.isOpen
                }
                navbar >
                <
                MDBNavbarNav left >
                <MDBNavItem >
                    { [1,2,3,4,5,6,7].includes(this.state.roleID) &&
                         <MDBNavLink exact={true} to = "/" > Dispatch < /MDBNavLink>
                    }
                    { [1,2].includes(this.state.roleID) &&
                        <MDBNavLink to = "/devices" > Assets < /MDBNavLink>
                    }
                    { [1,2].includes(this.state.roleID) && 
                        <MDBNavLink to = "/users" > Users < /MDBNavLink>
                    }
                </MDBNavItem >

                <
                /MDBNavbarNav> <
                MDBNavbarNav right >
                <
                MDBNavItem >
                <
                MDBDropdown >
                <
                MDBDropdownToggle nav caret >
                <
                MDBIcon icon = "user" / >
                <
                /MDBDropdownToggle> <
                MDBDropdownMenu className = "dropdown-default"
                right >
                <
                MDBDropdownItem href = "#!"
                onClick = {
                    this.handleLogout
                } >
                Log Out <
                /MDBDropdownItem> < /
                MDBDropdownMenu > <
                /MDBDropdown> < /
                MDBNavItem > <
                /MDBNavbarNav> < /
                MDBCollapse > <
                /MDBNavbar>

                <
                div className = "RoutesContainer" >
                <
                Routes logoutMethod={this.handleLogout} props = {
                    this.state.companyID
                }
                / > < /
                div >




                <
                /div> < /
                MenuContext.Provider >
            );

        } else {
            return ( <
                MenuContext.Provider value = {
                    {
                        locateMenu
                    }
                } >
                <
                div className = "App" >
                <
                MDBNavbar color = "black"
                dark expand = "md"
                className = "sticky-top" >
                <
                MDBNavbarBrand >
                <
                strong className = "white-text" > 
                MATS
                 < /strong > < /
                MDBNavbarBrand > <
                MDBNavbarToggler onClick = {
                    this.toggleCollapse
                }
                /> <
                MDBCollapse id = "navbarCollapse3"
                isOpen = {
                    this.state.isOpen
                }
                navbar >
                <
                MDBNavbarNav left >
                <
                MDBNavItem >
                <
                /MDBNavItem>

                <
                /MDBNavbarNav> <
                MDBNavbarNav right >
                <
                MDBNavItem >
                <
                MDBDropdown >
                <
                MDBDropdownToggle nav caret >
                <
                MDBIcon icon = "user" / >
                <
                /MDBDropdownToggle> <
                MDBDropdownMenu className = "dropdown-default"
                right >
                <
                MDBDropdownItem href = "#!"
                onClick = {
                    this.handleLogout
                } >
                Log Out <
                /MDBDropdownItem> < /
                MDBDropdownMenu > <
                /MDBDropdown> < /
                MDBNavItem > <
                /MDBNavbarNav> < /
                MDBCollapse > <
                /MDBNavbar>

                <div className = "RoutesContainer" > 
                {!this.state.waitingVerification &&
                    <div className = "failedLogin" > We're sorry  - we can't find a company attached to your user information. < br / > < br / > Please make sure that your email address and / or phone number matches with what is on file with your company to ensure you 've been granted access.</div>
                } 
                {this.state.waitingVerification &&
                    <div className = "failedLogin" > Loading MATS</div>
                }                
                </div>




                <
                /div> < /
                MenuContext.Provider >
            );
        }

    }
}


const matsTheme = {
    root: {
        background: "#000",
    },
    container: {
        height: "100vh",
        flex: 1,
        justifyContent: 'center',
        paddingTop: 0,
        margin: 0,
    },
    formContainer: {
        minWidth: "460px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "25px",
        boxShadow: "1px 1px 4px 0 rgba(0,0,0,0.15)",
    },
    button: {
        d: "rgb(2,0,36)",
        background: "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(24,154,170,1) 0%, rgba(120,192,67,1) 100%)"
    }
};

// export default withAuthenticator(App, { theme: matsTheme, includeGreetings: false 
// }
// );

export default withAuthenticator(App, {
    theme: matsTheme,
    includeGreetings: false,
    authenticatorComponents: [ <
        CustomSignIn / > , <
        ConfirmSignIn / > , <
        VerifyContact / > , <
        SignUp / > , <
        ConfirmSignUp / > , <
        ForgotPassword / > , <
        RequireNewPassword / >
    ]
});


//export default withRouter(App);
//export default App;