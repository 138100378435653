import 'babel-polyfill'; 
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';

import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap-css-only/css/bootstrap.min.css'; 
//import 'mdbreact/dist/css/mdb.css';


import './index.css';
import App from './App';
//import AppWithAuth from './AppWithAuth';

import { BrowserRouter as Router } from 'react-router-dom';




import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify'
import config from './aws-exports'

//polyfill()


//Amplify.configure(config)

Amplify.configure({
    ...config,
    Analytics: { 
      disabled: true
    }
});


ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
