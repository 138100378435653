import React, { Component, useState } from "react"
//import { MenuContext } from "../App"

//import { render } from 'react-dom'
import Modal from 'react-modal'

//Select Box
import Select from 'react-select'

import "./Home.css"

// AWS authentication
import { Auth, API } from "aws-amplify"

//Maps and other UI elements
import {Map as GMap, Marker, Polygon, InfoWindow, GoogleApiWrapper} from 'google-maps-react'
import { Button, Form } from "react-bootstrap"
import InfoWindowEx from './infowin'

// IdleTimer to detect inactivity
import IdleTimer from 'react-idle-timer'

// Sliding side panels
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';



// Google Maps API Key
const key = 'AIzaSyCgFe5yGlpxpApeJmdLTDPploLUR7EDCE0'

//const { locateMenu } = useContext(MenuContext);

const star = `<?xml version="1.0" ?><svg height="60px" version="1.1" viewBox="0 0 60 60" width="60px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title/><desc/><defs/><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g id="Social_icons" transform="translate(-422.000000, -1955.000000)"><g id="Geo" transform="translate(422.000000, 1955.000000)"><circle cx="30" cy="30" fill="#09BD9F" id="Oval" r="30"/><path d="M19.1303436,29.259614 C21.9811466,35.9557517 30.0345121,48 30.0345121,48 C30.0345121,48 39.3658203,33.3885602 41.2761719,28.308284 L41.2633872,28.308284 C41.7398361,27.002542 42,25.5911245 42,24.1183432 C42,17.4255671 36.627417,12 30,12 C23.372583,12 18,17.4255671 18,24.1183432 C18,25.9563868 18.4052119,27.6988548 19.1303436,29.259614 L19.1303436,29.259614 Z M30,31 C33.8659932,31 37,27.8659932 37,24 C37,20.1340068 33.8659932,17 30,17 C26.1340068,17 23,20.1340068 23,24 C23,27.8659932 26.1340068,31 30,31 L30,31 Z" fill="#FFFFFF" id="Combined-Shape"/></g></g></g></svg>`
//const dayrange = Array.from(new Array(362),( val, index) => index+4 )

var dayrange = []

for (var i = 4; i<366; i++) {
    dayrange.push({key: ""+i+"", label: "Minimum Days Out: "+i+"" , value: i});
  }



//UPDATE TIMER 
var interval; 


// PAGE VISIBILITY 
let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}


// Creates ICON for Side Panel Asset Details
function createIcon(icon) { 
  var encodedData = "data:image/svg+xml;base64,"+window.btoa(icon.icon);
  return encodedData 
}

// Update Marker Values
function editMarker(markers, detail, markertime, tempMarker){
  return markers.map(item => {
      var temp = Object.assign({}, item);
      if (temp.key === detail.assets_id) {
          temp.markertime = markertime
          temp.value = detail.asset_name
          temp.label = detail.asset_name
          temp.lat = detail.latitude
          temp.lng =  detail.longitude
          temp.icon = tempMarker
          temp.driver = detail.driver_notified
          temp.maintenance = detail.in_maintenance
          temp.job_start = detail.job_start 
          temp.job_shipped = detail.job_shipped
          temp.job_end = detail.job_end 
          temp.last_updated = detail.last_report_timestamp
          temp.load_current = detail.load_current
          temp.serial = detail.serial
          temp.time_stamp = detail.time_stamp
          temp.jobs_master_id = detail.jobs_master_id 
      }
      return temp;
  });
}

function editMarker_update(markers, detail, markertime, tempMarker){
  return markers.map(item => {
      var temp = Object.assign({}, item);
      if (temp.key === detail.key) {
          temp.markertime = markertime
          temp.value = detail.value
          temp.label = detail.label
          temp.lat = detail.lat
          temp.lng =  detail.lng
          temp.icon = tempMarker
          temp.driver = detail.driver
          temp.maintenance = detail.maintenance
          temp.job_start = detail.job_start 
          temp.job_shipped = detail.job_shipped
          temp.job_end = detail.job_end 
          temp.last_updated = detail.last_report_timestamp
          temp.load_current = detail.load_current
          temp.serial = detail.serial
          temp.time_stamp = detail.time_stamp
          temp.jobs_master_id = detail.jobs_master_id 
      }
      return temp;
  });
}

// Create Bar Chart to Show Individual Job Loadouts
function createJobChart( data ) {
  let toReturn = "";

  if(data!==null){
    let segments = Object.keys(data).length;
    let percentage = 100/segments
    let markers = ""

    //console.log(segments, percentage)
    toReturn = "<svg width='100%' height='10px'><rect fill='#ccc' width='100%' height='7'></rect>"

    Object.keys(data).forEach(function (item, key) {
        // Process each section, add shading if true
          //console.log("Key:",key,"Item:",item, "Value:",data[item])

          // Add Markers
          markers += " <rect fill='#001f3f' x='"+( percentage * key )+"%' y='0' width='2' height='10'></rect> "
          // IF BIN IS TRUE THEN COLOR IN
          if(data[item]===true){
            toReturn += "<rect fill='#0074d9' x='"+( percentage * key )+"%' y='0'width='"+percentage+"%' height='7'></rect>"
          }
    })

     toReturn += "<g class='markers'>"+markers+"</g></svg>"
  }

  //console.log(toReturn)
  return toReturn
}

// Calculate Sectors for Marker
function calculateSectors( data ) {
  var sectors = [];

  var l = data.size / 2
  var a = 0 // Angle
  var aRad = 0 // Angle in Rad
  var z = 0 // Size z
  var x = 0 // Side x
  var y = 0 // Side y
  var X = 0 // SVG X coordinate
  var Y = 0 // SVG Y coordinate
  var R = 0 // Rotation

  data.sectors.map( function(item, key ) {
      let aCalc,arcSweep;
      a = 360 * item.percentage;
      aCalc = ( a > 180 ) ? 360 - a : a;
      aRad = aCalc * Math.PI / 180;
      z = Math.sqrt( 2*l*l - ( 2*l*l*Math.cos(aRad) ) );
      if( aCalc <= 90 ) {
          x = l*Math.sin(aRad);
      }
      else {
          x = l*Math.sin((180 - aCalc) * Math.PI/180 );
      }
      
      y = Math.sqrt( z*z - x*x );
      Y = y;

      if( a <= 180 ) {
          X = l + x;
          arcSweep = 0;
      }
      else {
          X = l - x;
          arcSweep = 1;
      }

      sectors.push({
          percentage: item.percentage,
          color: item.color,
          arcSweep: arcSweep,
          L: l,
          X: X,
          Y: Y,
          R: R
      });

      R = R + a;

      return null
  })


  return sectors
}

// Generate colors based off luminance
function ColorLuminance(hex, lum) {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
}

// Create Doughnut Markers
function createMarker(detail, id, map, serial, maintenance, battery, started, ended, shipped) {
  //console.log("Serial", serial)
  var containerType = 0
  //var segments = 0
  //var spClass = "mapplace"

  //Updated colors
  let update = new Date(shipped);
  let prevDate = update.getTime();

  let current = new Date();
  let curDate = current.getTime();

  let evaltime = 0

  if(shipped !== null && ended === null){
    evaltime = parseInt((curDate - prevDate) / (1000 * 3600 * 24))
  }

  

  let eColorBack = "#FFFFFF"
  let eColorFront = "#000000"




//   if(evaltime >= 4){ console.log("EVAL TIME: ", started, current, evaltime, id) }

  // Variables to store luminosity for markers
  var color_a_lum = 0
  var color_a_base = "#00baff";
  var color_b_lum = 0
  var color_b_base = "#FF0000";

  let tempsectors = []

  Object.keys(detail).length !== 0 && detail.constructor === Object
    ? (containerType = Object.keys(detail).length)
    : (containerType = 0)

  // CHECK IF SUBCONTAINERS OR SINGLE VALUE
  if (containerType !== 0) {
    var color = "red";

    let segments = containerType;
    //let segmentSize = 100 / segments;
    //let segmentSizeEmpty = 100 - segmentSize;
    let segPercent = (100/segments)*.01;

    Object.keys(detail).forEach(function(key, index) {
      if (detail[key] === true) {
        color=ColorLuminance(color_a_base, color_a_lum);
        color_a_lum = color_a_lum - 0.5/parseInt(segments);
      } else {
        color=ColorLuminance(color_b_base, color_b_lum);
        color_b_lum = color_b_lum - 0.5/parseInt(segments);
      }


      tempsectors.push({
                  percentage: segPercent, 
                  color: color
                })
    });
  } else {
    //let segments = 1;
    //let segmentSize = detail;
    //let segmentSizeEmpty = 100 - segmentSize;
    //let locb = loc - segmentSize;

    // SINGLE PERCENTAGE CONTAINER CODE HERE

  }

var data = {
  size: 230,
  sectors: tempsectors
}

// Calculate proper archs for each bin
let sectors = calculateSectors(data);

// Generate SVG for marker
var tempr = `<svg width="230" height="230" viewBox="0 0 230 230"  xmlns="http://www.w3.org/2000/svg">
                <style>
                  .small { font: bold 50px sans-serif;  fill: black;}
                  .smallv { font: bold 50px sans-serif;  fill: red;}
                  .pulse {
                    stroke-width: 75px;
                    stroke-opacity: 1;
                    fill: white;
                    fill-opacity: 0;
                    transform-origin: 50% 50%;
                    animation-duration: 5s;
                    animation-name: pulse;
                    animation-iteration-count: infinite;
                  }
                  
                  @keyframes pulse {
                    from {
                      opacity: 1;
                      transform: scale(0.3);
                    }
                    to {
                      opacity: 0;
                      transform: scale(1);
                    }
                  }

                  .red{
                      stroke: red;
                  }      
                  
                  .yellow{
                      stroke: yellow;
                  }

                </style>
                <polygon points="0,0 115,0 0,115" class="triangle"  fill="#FFF"/>
                <path fill="#a91818" d="M0,115a115,115 0 1,0 230,0a115,115 0 1,0 -230,0 z"></path>`

var temps =  "";

sectors.map((sector, index) => {
                temps += '<path fill="'+sector.color+'" d="M' + sector.L + ',' + sector.L + ' L' + sector.L + ',0 A' + sector.L + ',' + sector.L + ' 1 0,1 ' + sector.X + ', ' + sector.Y + ' z" transform="rotate('+sector.R+', 115, 115)"></path>'

                return null
})
var tempt; 
if( serial !== null){
      tempt = `
               <text text-rendering="optimizeSpeed" x="115" y="115" text-anchor="middle" alignment-baseline="central" class="small">`+id+`</text>
              `
  }
else{ 
      tempt = `  <path fill="#FFFFFF" d="M45,115a70,70 0 1,0 140,0a70,70 0 1,0 -140,0 z"></path>  
            <text x="115" y="115" text-anchor="middle" alignment-baseline="central" class="smallv">`+id+`</text>
            `  
  }          

var tempu = `</svg>`

var temprr;

if(maintenance === true){
  temprr = '<circle cx="115" cy="115" r="70" stroke="yellow" fill="yellow" stroke-width="4" />'
}
else{
  temprr = '<circle cx="115" cy="115" r="70" stroke="white" fill="white" stroke-width="4" />'
}

var tempe;

if(evaltime > 7 ){
   tempe =  '<circle class = "pulse red" cx="115" cy="115" r = "70px" />'
  }
  else if(evaltime <=7 && evaltime >=4 ){
    tempe =  '<circle class = "pulse yellow" cx="115" cy="115" r = "70px" />'    
  }


var temp = tempr+temps+temprr+tempe+tempt+tempu
            

  return temp;
}

// COMPARE AND SORT MARKERS
function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();

  let comparison = 0;
  if (labelA > labelB) {
    comparison = 1;
  } else if (labelA < labelB) {
    comparison = -1;
  }
  return comparison;
}

function compareAssets(a, b) {
  // Use toUpperCase() to ignore character casing
  const labelA = a.asset_name.toUpperCase();
  const labelB = b.asset_name.toUpperCase();

  let comparison = 0;
  if (labelA > labelB) {
    comparison = 1;
  } else if (labelA < labelB) {
    comparison = -1;
  }
  return comparison;
}

function compareDaysOut(a, b) {
    // Use toUpperCase() to ignore character casing
    let dayA = new Date(a.job_shipped);
    let timeA = dayA.getTime();

    let dayB = new Date(b.job_shipped);
    let timeB = dayB.getTime();

    let evaltime = 0

    if(dayA !== null && dayB !== null){
        let etime = parseInt((timeA - timeB) / (1000 * 3600 * 24))
        if(etime < 0 ) evaltime = -1
        if(etime = 0 ) evaltime = 0
        if(etime > 0 ) evaltime = 1
    }
    return evaltime;
  }

  // Filters Out Assets that do not have TTUS or timestamps that are 0
  function filterTTU(asset){
    let day = new Date(asset.job_shipped);
    let time = day.getTime();

    return time > 0
  }

  // Filter out assets that are not more than 4 days out
  function filterOld(asset){
    let day = new Date(asset.job_shipped);
    let time = day.getTime();

    let current = new Date();
    let curDate = current.getTime();

    let evaltime = 0

    if(asset.job_start !== null && asset.job_end === null){
        evaltime = parseInt((curDate - time) / (1000 * 3600 * 24))
    }  

    return evaltime > 3
  }

  function filterTime(minday) {
    return function(asset) {
        let day = new Date(asset.job_shipped);
        let time = day.getTime();
    
        let current = new Date();
        let curDate = current.getTime();
    
        let evaltime = 0
    
        if(asset.job_shipped !== null && asset.job_end === null){
            evaltime = parseInt((curDate - time) / (1000 * 3600 * 24))
        }  
    
        return evaltime > minday
    }
}

function compareDrops(a, b) {
  // Use toUpperCase() to ignore character casing
  const labelA = a.name.toUpperCase();
  const labelB = b.name.toUpperCase();

  let comparison = 0;
  if (labelA > labelB) {
    comparison = 1;
  } else if (labelA < labelB) {
    comparison = -1;
  }
  return comparison;
}

function compareDrivers(a, b) {
  // Use toUpperCase() to ignore character casing
  const labelA = a.fname.toUpperCase();
  const labelB = b.fname.toUpperCase();

  let comparison = 0;
  if (labelA > labelB) {
    comparison = 1;
  } else if (labelA < labelB) {
    comparison = -1;
  }
  return comparison;
}




// MAIN CLASS
export class Home extends Component {
  constructor(props) {
    super(props)

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)

    this.state = {
      map : null,
      userEmail: "",
      userPhone: "",
      userID: this.props.extra.props.user_id,
      companyID: this.props.extra.props.company_id,
      roleID: parseInt(this.props.extra.props.role_id),
      geofence: null,
      assets: [],
      inBounds: [],
      loaded: false, 
      lat: 26.802830,
      lng: -81.079920,
      latTemp: 26.803214,
      lngTemp: -81.075981,
      zoom: 10,
      markers: [],
      markerUpdatetime: 0,
      showLeftBar: false,
      asset: {id: 1, lat: 0, lng: 0},
      location: 2,
      isPaneOpen: false,
      isRightPaneOpen: false,
      selectedPlace: null,
      activeMarker: null,
      showingInfoWindow: false,
      activeDrop: null,
      showingDropWindow: false,   
      showInfoWindow: false,
      showLocate: false,
      dropMarker: {visible: false, lat: 26.802830, lng: -81.079920},
      copySuccess: "",
      showMarkers: true,
      showDrops: false,
      assetsActive: true,
      dropsActive:false,
      notifyActive: false,
      drops: [],
      dropsCompany: [],
      dropsDisplay: [],
      dropsDisplayFinal: null,
      dropsSelected: true,
      stateCompany: null,
      stateDrop: null,
      selectAssetSelected: null,
      selectCompanySelected: null,
      selectDropSelected:null,
      jobDetails: [],
      jobOverview: [],
      loadingDetails: false,
      driverDetails: [],
      notifyNumber: null,
      notifyDetails: [],
      notifyJob: null,
      showNotify: false, 
      notifyMessage: " ",
      shown: {},
      visibility: true,
      minDayTemp: 4,
      minDayOut: 4,
      addDrop: true,
      addDrop_name: "",
      addDrop_company_id: 0,
    }

    // Authentication
    Auth.currentAuthenticatedUser().then(result => {
      //console.log(result);
      if (result) {
        if (result.attributes.email_verified) {
          this.setState({ userEmail: result.attributes.email });
        }
        if (result.attributes.phone_number !== null) {
          let phone = result.attributes.phone_number;
          this.setState({ userPhone: phone });
        }
      } else {
        this.setState({ userEmail: "No Email Found" });
      }
    });
  }



// Runs when component mounts
componentDidMount() {
    //PAGE VISIBILITY LISTENER
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);

    this.getData()
    this.getDrivers()
    this.getJobStatus()
    Modal.setAppElement(this.el)

    //console.log("PROPS:",this.props.extra.props)
    console.log("ENABLE: Updating Asset Data")
    interval = setInterval(this.updateData,60000)

  }

// Runs when component unmounts
componentWillUnmount()    {
    console.log("DISABLE: No Longer Updating Asset Data")
    clearInterval(interval)
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }  


// Handle Visibilty Conditions
  handleVisibilityChange = () => {
    if (document[hidden]) {
     this.setState({ visibility: false });
    } else {
     this.setState({ visibility: true });
    }
  }


// Handle clicks on asset markers
onMarkerClick = (props, marker, e) => {
  this.setState({
    selectedPlace: props,
    isPaneOpen: true,
    showNotify: false
  })


  let target = props.id || '-1'
  let items = this.state.markers
  let foundIndex = items.findIndex(x => x.key === target)
  
  if(foundIndex !== -1){
    props.map.panTo({lat:items[foundIndex].lat, lng:items[foundIndex].lng},10);
    //var message = "Asset "+items[foundIndex].label+" READY to DELIVER. PICKUP : https://www.google.com/maps/search/?api=1&query="+items[foundIndex].lat+","+items[foundIndex].lng+" DELIVER TO: "+this.state.notifyJob.drop_latitude
    //this.setState({asset : items[foundIndex], notifyMessage: message});

    this.setState({asset : items[foundIndex] });
    
    

    //GRAB DETAILS FORM SERVER
    // console.log("MASTER JOB:"+ items[foundIndex].jobs_master_id)   
    this.getDetails(items[foundIndex].jobs_master_id);
    // console.log("Grabbing Data for Details")
    // console.log(this.state.jobDetails)


  }
}

// SETS MESSAGE TO NOTIFY DRIVER
updateMessage = (job, e) => {

  // Grab desired Pickup Asset Info
  let asset= 0 
  if ( e.target !== undefined ) {
    asset = e.target.value || 0
  } 
  let assets = this.state.assets
  let foundIndex = 0
  let assetDetails = null

  if(job !== null){
    let message = '';

    if(this.state.roleID === 7){
        message = "Asset "+this.state.asset.label+" for customer \n\n";
    }
    else{
        message = "Asset "+this.state.asset.label+" for customer "+job.customer_company+" \n\n";
    }

    // Construct Pickup Message
    if(this.state.roleID === 7){
        if(this.state.asset.lat !== null && this.state.asset.lng !== null){
        message = message + "// PICKUP : "+this.state.asset.label+"\nhttps://www.google.com/maps/search/?api=1&query=--.-------,--.------- \n\n"
        }
        else{
        message = message + "// PICKUP : "+this.state.asset.label+"\nCALL FOR LOCATION\n\n"
        }
    }
    else{
        if(this.state.asset.lat !== null && this.state.asset.lng !== null){
            message = message + "// PICKUP : "+this.state.asset.label+"\nhttps://www.google.com/maps/search/?api=1&query="+this.state.asset.lat+","+this.state.asset.lng+" \n\n"
            }
            else{
            message = message + "// PICKUP : "+this.state.asset.label+"\nCALL FOR LOCATION\n\n"
            }       
    }

    // Construct Drop Off Message
    if(this.state.roleID === 7){
        if((job.drop_latitude !== null && job.drop_longitude !== null) && (job.drop_latitude !== 0 && job.drop_longitude !== 0)){
        message = message + "// DELIVER TO : DROP \nhttps://www.google.com/maps/search/?api=1&query=--.-------,--.-------"
        }
        else{
        message = message + "// DELIVER TO : DROP \nCALL FOR LOCATION"
        }
    }
    else{
        if((job.drop_latitude !== null && job.drop_longitude !== null) && (job.drop_latitude !== 0 && job.drop_longitude !== 0)){
            message = message + "// DELIVER TO : "+job.drop_name+"\nhttps://www.google.com/maps/search/?api=1&query="+job.drop_latitude+","+job.drop_longitude 
            }
            else{
            message = message + "// DELIVER TO : "+job.drop_name+"\nCALL FOR LOCATION"
            }
    }

    // Construct Retrieve Message
    if(this.state.roleID === 7){    
        if( asset !== 0 ){
        foundIndex = assets.findIndex(x => x.assets_id === parseInt(asset))
        if(foundIndex > 0){
            assetDetails = assets[foundIndex]
            if(assetDetails.latitude === null || assetDetails.longitude === null){
                message = message + "\n\n// RETURN ASSET: "+assetDetails.asset_name+ "\nCALL FOR LOCATION"
            }
            else{
                message = message + "\n\n// RETURN ASSET: "+assetDetails.asset_name+ "\nhttps://www.google.com/maps/search/?api=1&query=--.----,--.----"
            }
        }
        }
    }
    else{
        if( asset !== 0 ){
            foundIndex = assets.findIndex(x => x.assets_id === parseInt(asset))
            if(foundIndex > 0){
              assetDetails = assets[foundIndex]
                if(assetDetails.latitude === null || assetDetails.longitude === null){
                  message = message + "\n\n// RETURN ASSET: "+assetDetails.asset_name+ "\nCALL FOR LOCATION"
                }
                else{
                  message = message + "\n\n// RETURN ASSET: "+assetDetails.asset_name+ "\nhttps://www.google.com/maps/search/?api=1&query="+assetDetails.latitude+","+assetDetails.longitude
                }
            }
          }
    }
    
    // SET MESSAGE
    this.setState({ notifyMessage: message });


  }
}

// Handle clicks on asset markers
onMarkerClickOverview = (markerid) => {
  this.setState({
    isPaneOpen: true,
    showNotify: false
  })

  //console.log(this.state.map)
  let map = this.state.map

  let target = markerid || '-1'
  let items = this.state.markers
  let foundIndex = items.findIndex(x => x.key === target)
  
  if(foundIndex !== -1){
    map.panTo({lat:items[foundIndex].lat, lng:items[foundIndex].lng},10)
    map.setZoom(22)
    this.setState({asset : items[foundIndex]});
    this.getDetails(items[foundIndex].jobs_master_id);

  }
}
// Set Maintenance for Asset and update status locally
setMaintenance = (props) => {
  
  let asset = props.asset.key
  let user = this.state.userID
  let status = !props.asset.maintenance

  let current = this.state.asset
  // Reverse states
   current.maintenance = !current.maintenance; 

  let target = current.key
  let newassets = this.state.assets
  let foundIndex = newassets.findIndex(x => x.assets_id === target)
  
  if(foundIndex !== -1){
    newassets[foundIndex].maintenance = current.maintenance
    this.setState({assets : newassets})
  }

  // Update Marker
  let old_markers = this.state.markers

  let loadinfo = void 0;

  let markertime = Date.now()

  // Use data from accumulators unless none found - then use default values
  if(current.load_current === null){
    loadinfo = current.load_details;
  }
  else{
    loadinfo = current.load_current;
  }


  let tempMarker = createMarker(loadinfo, current.label, true, current.serial_id, current.maintenance, current.battery, current.job_start, current.job_end, current.job_shipped)

  current.icon=tempMarker

  old_markers = editMarker_update(old_markers, current, markertime, tempMarker)

   // Update current state asset locally
   this.setState({ markers: old_markers, asset: current  })


   // CALL API to update remotely
   let myInit = {
    body: {
      asset: asset,
      user: user,
      status: status
    }, // replace this with attributes you need
    }

    API.post("mats", "/maintenance", myInit).then(response => {
        console.log("Maintenance Updated")
    }).catch(error => {
        console.log(error.response)
    });

}

// Send Notification to Driver
sendNotification = (props) => {
  let number = this.state.notifyNumber
  let message = this.state.notifyMessage

  let asset = props.asset.key
  let user = this.state.userID

  //console.log("Notification to ", number," Message: ", message)

  let myInit = {
    body: {
      phone: number,
      message: message,
      asset: asset,
      user: user
    }, // replace this with attributes you need
    }

    API.post("mats", "/messenger", myInit).then(response => {
        console.log("GOOD")
    }).catch(error => {
        console.log(error.response)
    });

    console.log("Message",myInit)

  this.setState({ showNotify : false })
}


// Recenter Map On Main Yard
centerHome = (map) => {
  // Hard coded for Wedgworth
  let lat = 26.802830
  let lng = -81.079920

  map.setCenter({lat: lat ,lng: lng })
  map.setZoom(17)

  this.setState({ map : map })

}

// Update when bounds change - show overview of assets
onBoundsChange = (props, map, e) => {
  var neBound = map.getBounds().getNorthEast()
  var swBound = map.getBounds().getSouthWest()
  //console.log("neBound: "+neBound+" swBound"+swBound)

  //Update List of Markers in Bounds
  const markers = this.state.markers

  var bounds = new props.google.maps.LatLngBounds(swBound, neBound);
  var inBounds = [] 
  for (var a in markers) {
      if (bounds.contains(new props.google.maps.LatLng(markers[a].lat, markers[a].lng))){
         inBounds.push(markers[a])
      }
  }
  this.setState({inBounds})
  //console.log(this.state.inBounds)
}

setCenter = (props, map, e) => {
  // Hard coded for Wedgworth
  let lat = 26.802830
  let lng = -81.079920

  map.setCenter({lat: lat ,lng: lng })

  this.setState({ map : map })
}

rightClick = (props,map,e) =>{
  //console.log("Registered")

    // Hard coded for Wedgworth
    let lat = 26.802830
    let lng = -81.079920

    lat = e.latLng.lat();
    lng = e.latLng.lng();
    // populate yor box/field with lat, lng
    //console.log("Lat=" + lat + "; Lng=" + lng);    
  
  this.setState({
    dropMarker : {
      visible : true,
      lat: lat,
      lng: lng
    }
  })
}

markerClick = (props, marker, e) => {
this.setState({
  selectedPlace: props,
  activeMarker: marker,
  showingInfoWindow: true, 
  showNotify: false
});

  //console.log("Copying Coordinates")
    let copyText = this.state.dropMarker.lat+", "+this.state.dropMarker.lng
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', copyText);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy); 
}


addDrop = (e) => {
    e.preventDefault()
    //console.log(e.target.elements.dropadd.value)
    //console.log("CompanyID:"+this.state.companyID+" Drop Name: "+e.target.dropadd.value+" CustomerID: "+this.state.addDrop_company_id+" LAT: "+this.state.dropMarker.lat+" Lng: "+this.state.dropMarker.lng)

    const params = {
        body: {
            companyId: this.state.companyID,
            customerId: this.state.addDrop_company_id,
            lat: this.state.dropMarker.lat,
            lon: this.state.dropMarker.lng,
            dropName: e.target.dropadd.value,
        },
    };

    API
     .post("mats", "/drops", params )
     .then(response => {
        //console.log("Posting new Drop")
        alert("Drop has been added")
        //console.log("Drop added, clearing name",params)
        
    }).catch(error => {
        //console.log("DROP ADD ERROR: ",error)
        alert("There has been an error adding this drop, please try again.")
    });
    
    // Clear name in form
    if(e.target.dropadd !== null){
        e.target.dropadd.value = "";
    }


    
}



onMapClicked = (props) => {
if (this.state.showingInfoWindow) {
  this.setState({
    showingInfoWindow: false,
    showingDropWindow: false,
    activeMarker: null,
    activeDrop: null,
    copySuccess: ''
  })
}
};


moveMarker = (props,map,e) => {
    // Hard coded for Wedgworth
    let lat = 26.802830
    let lng = -81.079920

    lat = e.latLng.lat();
    lng = e.latLng.lng();
    // populate yor box/field with lat, lng
    //console.log("Lat=" + lat + "; Lng=" + lng);    
  
  this.setState({
    dropMarker : {
      visible : true,
      lat: lat,
      lng: lng
    },
    showingInfoWindow: false,
    activeMarker: null
  })
}

selectChange = (e) => {
  //Find array value to store current position in state
  if(e !== null){
    let targetItem = this.state.markers.findIndex(x => x.value === e.value) || null    
    this.setState({ selectAssetSelected : targetItem})
  }
  else{
    this.setState({ selectAssetSelected : null})
  }
  // Move Map and Zoom
  if(e!==null){
    if(e.lat !== null && e.lng !== null){
      let map = this.state.map
      map.panTo({lat: e.lat ,lng: e.lng })
      map.setZoom(22)
    }
  }
}

selectChangeNotify = (e) => {
  //Find array value to store current position in state
  if(e !== null){
    let targetItem = this.state.markers.findIndex(x => x.value === e.value) || null    
    this.setState({ selectAssetSelected : targetItem})
  }
  else{
    this.setState({ selectAssetSelected : null})
  }
  // Move Map and Zoom
  if(e!==null){
    if(e.lat !== null && e.lng !== null){
      let map = this.state.map
      map.panTo({lat: e.lat ,lng: e.lng })
      map.setZoom(22)
    }
  }
}

selectCompany = (e) => {
  if( e !== null){
  // Set Company ID to State
  let targetItem = this.state.dropsCompany.findIndex(x => x.value === e.value)

  // Sort drops
  let allDrops = this.state.drops.sort(compareDrops)

  // Filter Drops by Company
  const selectedDrops = allDrops.filter(i => i.id === e.value)

  let markers = []
  const {lat,lng} = this.state

  if (selectedDrops.length > 1) {
    // Add an All Option to Markers
    markers.push({ key: 'drop-all', label : 'ALL DROPS', value : "0"})
  }
  
  selectedDrops.forEach(function(detail){
    if(detail.latitude == null || detail.longitude ==null ){
      markers.push({key: 'drop-'+detail.id+'-'+detail.drops_id, value: detail.drops_id, label: detail.name, lat: lat, lng: lng, company: detail.company })
    }
    else{
      markers.push({key: 'drop-'+detail.id+'-'+detail.drops_id, value: detail.drops_id, label: detail.name, lat: detail.latitude, lng: detail.longitude, company: detail.company })
    }
  })

  //console.log(selectedDrops)
  this.setState({ selectCompanySelected : targetItem, dropsDisplay : markers})

  // Set isDisabled for Specific Drop
  e === null ? this.setState({ dropsSelected : true}) : this.setState({ dropsSelected : false})
  }
  else{
    this.setState({ selectCompanySelected : null, dropsDisplay: [], dropsSelected: true, dropsDisplayFinal: [] })
  }
  

}

selectCompany_drop = (e) => {
    //console.log("COMPANY ID: "+e.value)
    if( e !== null){

    //this.setState({ addDrop_company_id : e.value }, ()=>{console.log("COMPANY: "+this.state.addDrop_company_id)})
    this.setState({ addDrop_company_id : e.value })
  }
}

// select drops based of DROP SELECT BOX
selectDrop = (e) => {
  if( e !== null ){ 
    // SET TARGET ITEM FOR SELECT BOX STATE
    let targetItem = this.state.dropsDisplay.findIndex(x => x.value === e.value)
    if (e.value === "0" ) targetItem = 0

    if(e.value === "0"){
      // SHOW ALL DROPS
      this.setState({ dropsDisplayFinal : this.state.dropsDisplay,  selectDropSelected : targetItem })
    }
    else{
      // SHOW ONLY SINGLE VALUE
      const selectedDrop = this.state.dropsDisplay.filter(i => i.value === e.value)
      this.setState({ dropsDisplayFinal : selectedDrop,  selectDropSelected : targetItem })
      let map = this.state.map
      map.panTo({lat: e.lat ,lng: e.lng })
    }
  }
  else{
    this.setState({ dropsDisplayFinal : [], selectDropSelected : null })
  }
}

// select drops based off ASSET DETAIL click of show drop button
selectDropFromAsset = (e) => {
  if( e !== null ){ 
    // SHOW ONLY SINGLE VALUE
    let marker = []

    const detail = this.state.drops.filter(i => i.drops_id === e)
    if( this.state.roleID === 7) {
        marker.push({key: 'drop-'+detail[0].id+'-'+detail[0].drops_id, value: detail[0].drops_id, label: "Drop Location", lat: detail[0].latitude, lng: detail[0].longitude, company: detail[0].company })
    }
    else{
        marker.push({key: 'drop-'+detail[0].id+'-'+detail[0].drops_id, value: detail[0].drops_id, label: detail[0].name, lat: detail[0].latitude, lng: detail[0].longitude, company: detail[0].company })

    }
    
    this.setState({ dropsDisplayFinal : marker })
    let map = this.state.map
    map.panTo({lat: detail[0].latitude ,lng: detail[0].longitude })
    }
  else{
    this.setState({ dropsDisplayFinal : []})
  }
}

// SELECT WHICH PANEL TO SHOW IN THE MORE DETAILS SLIDEPANEL ON RIGHT SIDE
panelButton = (e) => {
  switch(e) {
    case "assets":
      this.setState({assetsActive: true, dropsActive : false, notifyActive: false })
      break;
    case "drops":
      this.setState({assetsActive: false, dropsActive : true, notifyActive: false })
      break;
      case "notify":
        this.setState({assetsActive: false, dropsActive : false, notifyActive: true })
        break;      
    default:
      // code block
      break;
  }
}

//Show Drops based on selections in Drops Menu
dropClick = (e) => {
  console.log("Button Click")
}

//Clears Drops From Map
dropClear= (e) => {
  this.setState({ dropsDisplayFinal : [] })
}

//Hide Assets when selected to from the Drops Menu
assetsHide = (e) =>  {
  this.setState({ showMarkers: !e.target.checked })
}

overdueChange = (e) => {
    //console.log(dayrange)
    if( e !== null){
        let final = parseInt (e.value)
        this.setState({ minDayOut : final })
    }
}



// GET ASSET JOB DETAILS
getDetails = async (target) => {
    try {
      this.setState({loadingDetails: true, jobDetails : []})
      let details= await API.get("mats", "/jobs/"+target);

      // For Storing Return Final Array
      let finalArray =[]

      // Grab Unique Values from Arraay of Objects
      const values = [...new Set(details.drops.map(item => item.drop_id))]

      //now loop through each of the values and determine what the new array will look like after processing each job
      values.forEach(function(value){
        let result = details.drops.filter(item => item.drop_id === value)
        let notes = ""


        //console.log("Asset Details", result) 

        // Craft single object with combined values
        result.forEach(function(note){
          //Get Barchart for specific job
          let barchart = createJobChart( note.load_details )

          //notes += note.notes+"<br />"+JSON.stringify(note.load_details)+"<br /><br />"
          notes += note.notes+"<span className='barchart'>"+barchart+"</span>"
        })
        finalArray.push({ ...result[0], notes: notes})
      })

      this.setState({ jobDetails : finalArray, loadingDetails: false })

    }catch (err) {
      console.log("error fetching data.."+err)
    }
}

 // GET DRIVERS
 getDrivers = async () => {
  try {
    let details = await API.get("mats", "/users/")
    let finalArray = details.users.sort(compareDrivers)

    this.setState({ driverDetails : finalArray })

    //console.log("Driver Details", this.state.driverDetails)

  }catch (err) {
    console.log("error fetching data.."+err)
  }
} 

 // GET JOB STATUS
 getJobStatus = async () => {
  try {
    let jobs = await API.get("mats", "/jobstatus/")

    //console.log(jobs.data)

    jobs.data.reduce(function (r, a) {
      r[a.asset_id] = r[a.asset_id] || [];
      r[a.asset_id].push(a);
      return r;
  }, Object.create(null));
    
    //console.log(res, result )

    this.setState({ jobOverview : jobs.data })

    //console.log("Job Overview", this.state.jobOverview)

  }catch (err) {
    console.log("error fetching data.."+err)
  }
} 

// GET ALL ASSET AND DROP INFO ON LOAD
getData = async () => {
    // PULL ASSET DATA
    try {
      this.setState({ loadingmsg: "assets..." });
      var data = await API.get("mats", "/assets");
      //console.log("data from Lambda REST API: ", data.assets);

      let useData = data.assets.sort(compareAssets);
      this.setState({ assets: useData });

      this.setState({ loadingmsg: "drops..." });
      var drops = await API.get("mats", "/drops");
      this.setState({ drops: drops.drops })

      //console.log("Drops",drops.drops)


      // PROCESS ASSET DATA
      this.setState({ loadingmsg: "markers..." });
      const {markers,lat,lng,latTemp,lngTemp} = this.state

      let markertime = Date.now()
      data.assets.forEach(function(value) {
       let detail = value;

      // Determine Pin Type
      // If bin is specified then show bin levels
      // let containerType = 0; //0 for single value i.e. a percentage fill, number for size of container i.e. 4 bins

      let loadinfo = void 0;

      // Use data from accumulators unless none found - then use default values
      if(detail.load_current === null){
        if(detail.load_details !== null){
           // OVERWRITE LOAD DETAILS IF JOB CONCLUDED 
            if(detail.job_end !== null){
                detail.load_details = {"bin1":false,"bin2":false,"bin3":false,"bin4":false}
            }
          loadinfo = detail.load_details
        }
        else{
          loadinfo = '{"bin1":false}'
        }
      }
      else{
        if(detail.job_end !== null){
            detail.load_current = {"bin1":false,"bin2":false,"bin3":false,"bin4":false}
        }      
        loadinfo = detail.load_current;
      }

      var tempMarker = createMarker(loadinfo, detail.asset_name, true, detail.serial_id, detail.in_maintenance, detail.battery, detail.job_start, detail.job_end, detail.job_shipped);

      //console.log(detail)
      if(detail.latitude == null || detail.longitude == null ){
        markers.push({key: detail.assets_id, markertime: markertime, value: detail.asset_name, label: detail.asset_name, lat: latTemp, lng: lngTemp, icon: tempMarker, driver: detail.driver_notified, maintenance: detail.in_maintenance, battery: detail.battery, job_start: detail.job_start, job_shipped: detail.job_shipped, job_end: detail.job_end, last_updated: detail.last_report_timestamp, load_current: detail.load_current, serial: detail.serial, time_stamp: detail.time_stamp, jobs_master_id: detail.jobs_master_id })
      }
      else{
        markers.push({key: detail.assets_id, markertime: markertime, value: detail.asset_name, label: detail.asset_name, lat: detail.latitude, lng: detail.longitude, icon: tempMarker, driver: detail.driver_notified, maintenance: detail.in_maintenance, battery: detail.battery, job_start: detail.job_start, job_shipped: detail.job_shipped, job_end: detail.job_end, last_updated: detail.last_report_timestamp, load_current: detail.load_current, serial: detail.serial, time_stamp: detail.time_stamp, jobs_master_id: detail.jobs_master_id })
      }

      })



      //console.log(this.state.drops)

      // PROCESS DROPS DATA
      let result = []
      let map = new Map()

      drops.drops.forEach( function(value) {      
        if(!map.has( value.id )){
            map.set( value.id, true)

            result.push({
                key: 'company-'+value.id,
                value: value.id,
                label: value.company,
                markertime: markertime
            })
            
        }
      })

      this.setState({ dropsCompany: result })
      //console.log("dropsCompany ",result)

      // Sort Markers
      let markersSorted = []
      markersSorted = markers.sort(compare)

      this.setState({markers:markersSorted})  

      // APPLY GEOFENCE
      if(this.state.companyID === 1){
        var geofence = [
            { lng : -81.075485, lat : 26.806364},
            { lng : -81.081171, lat : 26.806364},
            { lng : -81.081171, lat : 26.801356},
            { lng : -81.078628, lat : 26.801356},
            { lng : -81.078628, lat : 26.802917},
            { lng : -81.075485, lat : 26.802917},
            { lng : -81.075485, lat : 26.806364}
            ]
        this.setState({ geofence : geofence })
      }


      //Remove Overlay now that data has loaded
      this.setState({ loaded : true, markerUpdatetime: Date.now() })
      console.log("Markers Updated", this.state.markerUpdatetime)

    } catch (err) {
      console.log("error fetching data.."+err)
    }


    // PULL DROP DATA
    try {


      //console.log(result);

    } catch (err) {
      console.log("error fetching data.."+err)
    }  
  };

// GET ALL ASSET AND DROP INFO ON LOAD
updateData = async () => {

  if(this.state.visibility){
  console.log("Updating Asset Data")

  //Grab Original Markers
  let old_markers = this.state.markers
  let prev_update = this.state.markerUpdatetime

  // PULL ASSET DATA
  try {
    var data = await API.get("mats", "/assets")
    
    let assets =  data.assets.sort(compareAssets)
    let markers = []

    // console.log("NEW DATA", assets);

    this.setState({ assets: assets })

    // PROCESS ASSET DATA
    //const {lat,lng} = this.state
    let markertime = Date.now()

    assets.forEach(function(value) {
     let detail = value;
     //let update = new Date(detail.last_update);
     let update = new Date(detail.last_report_timestamp);
     let myEpoch = update.getTime();
      if (myEpoch !== 0 && myEpoch >= prev_update){
        //console.log(" Found updated asset")
        //let target = old_markers.find(obj => obj.key === detail.assets_id);
        //console.log("OLD VALUE: ", target)

          // Determine Pin Type
          // If bin is specified then show bin levels
          //let containerType = 0; //0 for single value i.e. a percentage fill, number for size of container i.e. 4 bins

          let loadinfo = void 0;

            // Use data from accumulators unless none found - then use default values
            if(detail.load_current === null){
                if(detail.load_details !== null){
                // OVERWRITE LOAD DETAILS IF JOB CONCLUDED 
                    if(detail.job_end !== null){
                        detail.load_details = {"bin1":false,"bin2":false,"bin3":false,"bin4":false}
                    }
                loadinfo = detail.load_details
                }
                else{
                loadinfo = '{"bin1":false}'
                }
            }
            else{
                if(detail.job_end !== null){
                    detail.load_current = {"bin1":false,"bin2":false,"bin3":false,"bin4":false}
                }      
                loadinfo = detail.load_current;
            }

          // Object.keys(loadinfo).length !== 0 &&
          // loadinfo.constructor === Object
          //   ? (containerType = Object.keys(loadinfo).length)
          //   : (containerType = 0);

          var tempMarker = createMarker(loadinfo, detail.asset_name, true, detail.serial_id, detail.in_maintenance, detail.battery, detail.job_start, detail.job_end, detail.job_shipped)

          //console.log(detail)
          old_markers = editMarker(old_markers, detail, markertime, tempMarker)
          markers.push({key: detail.assets_id, markertime: markertime, value: detail.asset_name, label: detail.asset_name, lat: detail.latitude, lng: detail.longitude, icon: tempMarker, driver: detail.driver_notified, maintenance: detail.in_maintenance, battery: detail.battery, job_start: detail.job_start, job_shipped: detail.job_shipped, job_end: detail.job_end, last_updated: detail.last_report_timestamp, load_current: detail.load_current, serial: detail.serial, time_stamp: detail.time_stamp, jobs_master_id: detail.jobs_master_id })

      }
      
      })


    // Sort Markers
    let markersSorted = []
    markersSorted = old_markers.sort(compare)
    this.setState({markers:markersSorted, markerUpdatetime: Date.now() })  

    //console.log(markers, "Updated Markers", this.state.markerUpdatetime, old_markers)


  } catch (err) {
    console.log("error fetching data.."+err)
  }  
}
else{
    console.log("Pausing updates until browser is visible")
}
};  



// Toggle visibility of asset messaging block in right panel
  toggle(panelNumber) {
    this.setState({
         shown: {
             ...this.state.shown,
             [panelNumber]: !this.state.shown[panelNumber]
         }
     });
 }


  render() {
    function NotifyBlock(props){
      const jobs = props.jobs



      let assets = [];
      //console.log(jobs)

      let drop_id = void 0
      let prev_key = void 0

      Object.entries(jobs).forEach(entry => {
           let key = entry[0];
           let value = entry[1];

           //console.log("jobs",key, value,value.drop_id)

           

           // Look for matching drop ID, if it does then update previous record - else insert
          //  if(value.drop_id !== drop_id ){
          //   assets.push(value[key])
          //  }

           drop_id = value.drop_id
           prev_key = key

      //   let result = value.reduce(
      //     (map => (r, a) => (!map.has(a.drop_id) && map.set(a.drop_id, r[r.push({ drop_id: a.drop_id, jobs: 0 }) - 1]), map.get(a.drop_id).jobs++, r))(new Map),
      //     []
      //   );

      //   console.log("RES", result)

      //   assets.push(value[0].asset_name)

      //   //use key and value here
      //   if(value.length>1){
      //     // Count unique values to determine how many notification blocks are needed
          
      //   }
      });

     // console.log(assets)
      return(
        <div>
         {assets.map((job, index) => 
          <div key={"notify-job-"+index}>{job}
          </div>
          )}
        </div>
      );
    }   

    return (
      <div className="Home" ref={ref => this.el = ref}>

<IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 1} />


{!this.state.loaded &&  
        <div className="loader">
         <div className="loading-text">Loading {this.state.loadingmsg} </div>
         <div className="lds-facebook"><div></div><div></div><div></div></div>
        </div>  
} 

{!this.state.isRightPaneOpen && 
<button className="detailButton" onClick={() => this.setState({ isRightPaneOpen: true })}><i className="fas fa-eye"></i></button>
}

{!this.state.isRightPaneOpen && 
    <button className="detailButtonHome" onClick = { () => this.centerHome(this.state.map) }><i className="fas fa-home"></i></button>
}

<SlidingPane
    className='side-bar'
    width='350px'
    from='left'
    isOpen={ this.state.isPaneOpen }
    title='Asset Details'
    onRequestClose={ () => {
        // triggered on "<" on left top click or on outside click
        this.setState({ isPaneOpen: false });
    } }>
    <div className="asset_details">
          <div className="flex-container">
            <div className="asset_icon">
              <img alt={this.state.asset.label} key={this.state.asset.markertime} src={""+createIcon(this.state.asset)} />
            </div>
            <div className="asset_half">
            
            {this.state.asset.job_end === null &&
            (  <span>
                    <strong>Blended</strong>
                    <br /><AssetTime time = {this.state.asset.job_start} /><br /><br />
                </span>
            )
            }

            {this.state.asset.job_shipped !== null && this.state.asset.job_end === null && this.state.asset.serial !== null &&
            (  <span>
                    <strong>Shipped</strong>
                    <br /><AssetTime time = {this.state.asset.job_shipped} /><br /><br />
                </span>
            )
            }   

            {this.state.asset.job_shipped === null && this.state.asset.job_start !== null && this.state.asset.job_end === null && this.state.asset.serial !== null &&
            (  <span>
                <strong>Not Yet Shipped</strong>
                <br /><br />
            </span>
        )
            }                        

            {this.state.asset.job_end !== null &&  
            (  
            <span>
                <strong>Last Job</strong>
                <br /><AssetTime time = {this.state.asset.job_start} /> - <AssetTime time = {this.state.asset.job_end} /><br /><br />
            </span>
            )
            }
            <strong>Asset Last Reported</strong><br /><AssetTimeALR time = {this.state.asset.last_updated} /><br /><br />
            <strong>Battery</strong> <Battery battery= {this.state.asset.battery} /><br /><br />
            {this.state.asset.job_end === null &&
            (  <span>
                    <strong>Days Out</strong> <DaysOut asset = {this.state.asset} /><br /><br />
                </span>
            )
            }            
            </div>
          </div>
          <div>
          <div className="asset_details_details">
          {this.state.loadingDetails &&
              <img className="loadingIcon" alt="Loading Details" src="images/loading.gif" />
          }
          {!this.state.loadingDetails && this.state.asset.job_end === null && 
              this.state.jobDetails.map((job, index) =>
                <div key = {"jd-"+index} className="jd">
                    {this.state.roleID !== 7 && <div className="jdCustomer" >{job.customer_company}</div> }
                    {this.state.roleID === 7 && <div className="jdCustomer" >Client Details</div> }
                    <div className="jdNotes" ><span className="jdTitle">NOTES</span><span dangerouslySetInnerHTML={{__html: job.notes}} /></div>
                    <div className="jdDriverDetails" >
                      <span className="jdTitle">DRIVER ASSIGNED</span>
                     {(job.driver_first||job.driver_last) && <div>{job.driver_first} {job.driver_last}<br />
                      <a href={"tel:"+job.driver_phone}>{job.driver_phone}</a><br />
                      {job.driver_email}
                      </div> }
                     {(!job.driver_first && !job.driver_last) && <div>No driver assigned</div> }

                      <div className="jdNotes" ><span  className="jdTitle">DROP</span>
                      {this.state.roleID !== 7 && <div>{job.drop_name}<br />{job.drop_latitude}, {job.drop_longitude}<br /></div> }
                      {this.state.roleID === 7 && <div>Client Drop Location<br />--.------, --.------<br /></div> }
                      <button onClick = { () => this.selectDropFromAsset(job.drop_id) }>Show on Map</button> <button onClick = {this.dropClear}>Clear Marker</button>
                    </div>
                    { [1,2,5,7].includes(this.state.roleID) &&
                    <div>
                      <button className="notifyTitle"
                                onClick = { () => { this.setState({ notifyNumber: job.driver_phone, showNotify: !this.state.showNotify }); this.updateMessage(job,0); } }>
                                Notify Driver
                                </button>
                      <div className={`${this.state.showNotify === true ? 
                                "notifyOpen" : 
                                "notifyClose" }`}
                           id={'notifyDriver'+index} key={'notifyDriver'+index}>
                        { this.state.roleID !== 7 && 
                            <select className="notify" id="notifyDriverID" key="notifyDriverID"  defaultValue={job.driver_phone} onChange = { (event) => { this.setState({ notifyNumber: event.target.value });  }}>
                            <option key="nDriver-custom" value="">--- UNLISTED DRIVER ---</option>
                            {this.state.driverDetails.map((driver, index) =>
                            <option key={"driver"+driver.user_id} value={driver.phone}>{driver.fname} {driver.lname}</option>
                            )}
                            </select>
                        }
                        { this.state.roleID === 7 && 
                            <select className="notify" id="notifyDriverID" key="notifyDriverID"  defaultValue={job.driver_phone} onChange = { (event) => { this.setState({ notifyNumber: "111-222-3333" });  }}>
                            <option key="nDriver-custom" value="">--- UNLISTED DRIVER ---</option>
                            {this.state.driverDetails.map((driver, index) =>
                            <option key={"driver"+driver.user_id} value={driver.phone}>{driver.fname} {driver.lname}</option>
                            )}
                            </select>
                        }                        
                        <br />
                        <input className="notify"  id="notifyDriverPhone" key="notifyDriverPhone" value={this.state.notifyNumber  || ''} onChange = { (event) => { this.setState({ notifyNumber: event.target.value });     } }/>
                        <br /><br />
                        <span>Select asset to pickup after drop (optional)</span>
                        <select className="notifty optional" id="addpickup" key="addpickup" onChange={(e) => this.updateMessage(job,e)}>
                            <option key="default-pickup" value="0">NONE</option>
                            {this.state.assets.map((asset, index) =>
                              <option key={"pickupasset-"+asset.assets_id} value={asset.assets_id}>{asset.asset_name}</option>
                            )}
                        </select> 
                        <br /><br />
                        <textarea  className="notify" id={'notifyDriverMsg'+index} key={'notifyDriverMsg'+index} value={this.state.notifyMessage} onChange = { (event) => { this.setState({ notifyMessage: event.target.value }); } }></textarea>
                            {this.state.roleID === 7 && <button  disabled className="notifyButton" onClick={() => this.sendNotification({ asset : this.state.asset })} >Send Notification</button> }
                            {this.state.roleID !== 7 && <button  className="notifyButton" onClick={() => this.sendNotification({ asset : this.state.asset })} >Send Notification</button> }

                      </div>
                    </div>
                    } 
                    </div>

                </div>
                )
          }
          </div>
          
          </div>
         <div className='maintenanceblock'>
        { [1,2,4].includes(this.state.roleID)  &&
            !this.state.asset.maintenance &&
            <button className="mButton-green mButton" onClick={() => this.setMaintenance({ asset : this.state.asset })} >
                <span className="fa-stack fa-lg">
                <i className="fa fa-circle fa-stack-2x white"></i>
                <i className="fa fa-check fa-stack-1x green"></i>
                </span> Unit Working
                </button>
            }
        { [1,2,4].includes(this.state.roleID)  &&
             this.state.asset.maintenance &&
            <button className="mButton-red  mButton" onClick={() => this.setMaintenance({ asset : this.state.asset })} >
                <span className="fa-stack fa-lg">
                <i className="fa fa-circle fa-stack-2x white"></i>
                <i className="fa fa-times fa-stack-1x red"></i>
                </span> Unit Down
                </button>
            }
        { [3,5,6,7].includes(this.state.roleID)  &&
            !this.state.asset.maintenance &&
            <button className="mButton-green mButton">
                <span className="fa-stack fa-lg">
                <i className="fa fa-circle fa-stack-2x white"></i>
                <i className="fa fa-check fa-stack-1x green"></i>
                </span> Unit Working
                </button>
            }
        { [3,5,6,7].includes(this.state.roleID)  &&
             this.state.asset.maintenance &&
            <button className="mButton-red  mButton" >
                <span className="fa-stack fa-lg">
                <i className="fa fa-circle fa-stack-2x white"></i>
                <i className="fa fa-times fa-stack-1x red"></i>
                </span> Unit Down
                </button>
            }
         </div>
          <br /><br />

    </div>
</SlidingPane>

<SlidingPane
    className='side-bar'
    id = 'onscreenassets'
    width='350px'
    from='right'
    isOpen={ this.state.isRightPaneOpen }
    title='Overview'
    onRequestClose={ () => {
        // triggered on "<" on left top click or on outside click
        this.setState({ isRightPaneOpen: false });
    } }>
    <div className="overview">
      <div className="viewbar">
        <div onClick={() => this.panelButton("assets")} className={this.state.assetsActive ? "viewbar-segment viewbar-selected": "viewbar-segment"}><i className="fas fa-truck"></i><br />Assets</div>
{ this.state.roleID !==7 && <div onClick={() => this.panelButton("drops")} className={this.state.dropsActive ? "viewbar-segment viewbar-selected": "viewbar-segment"}><i className="fas fa-map-marker"></i><br />Drops</div> }
        <div onClick={() => this.panelButton("notify")} className={this.state.notifyActive ? "viewbar-segment viewbar-selected": "viewbar-segment"}><i className="fas fa-exclamation-circle"></i><br />Overdue</div>
      </div>
      <div className={this.state.assetsActive ? "masterAsset panel-display" : "masterAsset panel-hide"}>
        <div className="searchbar">
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            options={this.state.markers}
            placeholder="Search for Asset"
            onChange={this.selectChangeNotify}
            key="select-asset"
            defaultValue={this.state.markers[this.state.selectAssetSelected]}
            />
        </div>
        {this.state.inBounds.length === 0 ? ( <p className='sidebar_notice'>Move map to check which assets are in bounds</p> ) : ""}
        <div className="assetslist">
        {this.state.inBounds.map((marker, index) =>
            <div onClick={() => this.onMarkerClickOverview(marker.key)} className="overview_asset" key={"overview-"+marker.key}>
              <div className="flex-container asset_overview_block">
                <div className="asset_icon_overview">
                  <span dangerouslySetInnerHTML={{__html: marker.icon}} />
                </div>
                <div className="asset_right_overview">
                  <strong>Blended</strong>: <AssetTime time = {marker.job_start} /><br />
                  <strong>Shipped</strong>: <AssetTime time = {marker.job_shipped} /><br />
                  <strong>Reported</strong>: <AssetTimeALR time = {marker.last_updated} /><br /><br />
                </div>
            </div>
            </div>
        )}
        </div>
      </div>
      <div className={this.state.dropsActive ? "masterDrop panel-display": "masterDrop panel-hide"}>
        <div className="dropbar">
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              options={this.state.dropsCompany}
              placeholder="Select Company"
              onChange={this.selectCompany}
              key="dropsCompany"
              defaultValue={this.state.dropsCompany[this.state.selectCompanySelected]}
              />
            {!this.state.dropsSelected &&
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              options={this.state.dropsDisplay}
              placeholder=""
              onChange={this.selectDrop}
              defaultValue={this.state.dropsDisplay[this.state.selectDropSelected]}
              key="dropsListings"
              />    
            }          
              <Form.Check 
                type={"checkbox"}
                id={"showAssets"}
                checked={!this.state.showMarkers}
                label={"Hide Asset Markers"}
                onChange = {this.assetsHide}
              />


              <Button
                onClick = {this.dropClear}
                >
                Clear Drops From Map
              </Button>   
       
          </div>
          
      </div>
      <div className={this.state.notifyActive ? "masterDrop panel-display": "masterDrop panel-hide"}>
        <div className="assetOverdue">
        <div className="dropbar">
            <div className="row">
                <div className="col-12">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    options={dayrange}
                    placeholder="Select minimum days out (4)"
                    onChange={this.overdueChange}
                    key="overdueChange"
                    defaultValue={this.state.minDayOut}
                    />
                </div>
            </div>
    
          </div>            
        {this.state.markers.filter(filterTTU).filter(filterTime(this.state.minDayOut)).sort(compareDaysOut).map((marker, index) =>
            <div key={"notifyblock"+index} className="assetNotifyBlock">
            <div onClick={() => this.onMarkerClickOverview(marker.key)} className="overview_asset" key={"overview-"+marker.key}>
              <div className="flex-container asset_overview_block">
                <div className="asset_icon_overview">
                  <span dangerouslySetInnerHTML={{__html: marker.icon}} />
                </div>
                <div className="asset_right_overview">
                  <strong>Blended</strong>: <AssetTime time = {marker.job_start} /><br />
                  <strong>Shipped</strong>: <AssetTime time = {marker.job_shipped} /><br />
                  <strong>Reported</strong>: <AssetTimeALR time = {marker.last_updated} /><br />
                  <strong>Days Out</strong>: <DaysOut asset = {marker} /><br /><br />
                </div>
              </div>
            </div>
            </div>
        )}
        </div>
        </div>
    </div>
</SlidingPane>

<div id="map" className="mainmap">
    <GMap 
      google={this.props.google} 
      ref={(ref) => { this.mapRep = ref; }}
      zoom={this.state.zoom}
      disableDefaultUI = {true}
      mapTypeControl = {true}
      scaleControl= {true}
      zoomControl = {true}
      onBounds_changed={this.onBoundsChange}
      onReady = {this.setCenter}
      onRightclick = {this.rightClick}
      onClick = {this.onMapClicked}
      key='mainmap'
      >

    {this.state.geofence !== null && 
    <Polygon
          paths={this.state.geofence}
          strokeColor="#37dd1c"
          strokeOpacity={1.0}
          strokeWeight={4}
          fillColor="#fdff6f"
          fillOpacity={0.15} />
    }

    {this.state.markers.map((marker, index) =>
        <Marker     
           icon={{
              url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(marker.icon),
              scaledSize:  new this.props.google.maps.Size(50,50),
              size: new this.props.google.maps.Size(50,50),
              anchor: new this.props.google.maps.Point(0,0),
             }}
          key={marker.key}
          visible={this.state.showMarkers}
          //animation = {this.props.google.maps.Animation.DROP}
          id={marker.key}
          name={"mark-"+marker.key}
          position={{lat:marker.lat, lng:marker.lng}} 
          onClick={this.onMarkerClick}
          markertime = {marker.markertime}
          />
        )}  

    {this.state.dropsDisplayFinal !== null && this.state.dropsDisplayFinal.map((drop, index) => 
        <Marker     
          key={'finalDrops'+drop.key}
          animation = {this.props.google.maps.Animation.DROP}
          id={drop.key}
          label={drop.label.toString()}
          name={"drop-"+drop.key}
          position={{lat:drop.lat, lng:drop.lng}} 
          markertime = {drop.markertime}
          />
        )}      

      <Marker
                    icon={{
                      url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(star),
                      scaledSize:  new this.props.google.maps.Size(50,50),
                      size: new this.props.google.maps.Size(50,50),
                      anchor: new this.props.google.maps.Point(25,25),
                    }}      
                    key = 'dropmarker-01'
                    id = 'dropmarker-01'
                    draggable={true}
                    onDragend={this.moveMarker}
                    markertime = {0}
                    onClick={this.markerClick}
                    position = {{lat:this.state.dropMarker.lat, lng:this.state.dropMarker.lng}} />

              <InfoWindowEx
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}
                key= 'dropmarker-infowindow'
                >
                  <form onSubmit={this.addDrop} key="addDrop_form">
                    <div className="quickaddtitle">
                        Drop Pin Coordinates
                    </div>
                    <div className="quickcoord">
                        {this.state.dropMarker.lat+", "+this.state.dropMarker.lng}<br />
                        Coordinates copied to clipboard
                    </div>
                    <div className="quickadddrop">
                        <div className="quickaddtitle" key="qat">Quick Add Drop
                        </div>
                        <div>
                                <Select
                                    id="addSelect"
                                    name="addSelect"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    options={this.state.dropsCompany}
                                    placeholder="Select Company"
                                    onChange={this.selectCompany_drop}
                                    defaultValue={this.state.dropsCompany[this.state.selectCompanySelected]}
                                />   
                                <input key="addDropName" type="text" id="dropadd" name="dropadd" className="addDropInput" placeholder="Drop Name"  ></input>
                                <button type="submit" className="addDropButton" id="addButton" name="addButton"  >Add Drop</button> 
                        </div>
                    </div>
                  </form>
              </InfoWindowEx>        

    </GMap>
</div>


      </div>
    );
  }

  _onAction(e) {
    //console.log('user did something', e)
  }
 
  _onActive(e) {
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  _onIdle(e) {
   // console.log('user is idle', e)
    console.log('last active', this.idleTimer.getLastActiveTime())
  }

}



const AssetTimeOld = props =>
  props.time ? <span>{new Date(props.time).toLocaleString()}</span> : <span>Not available</span>;

const AssetTime = props => {
    let update = new Date(props.time);
    let prevDate = update.getTime();
  
    let current = new Date();
    let curDate = current.getTime();

    let toReturn = "";

    let style = "";

    if(props.time !== null){
         toReturn = ( <span>{new Date(props.time).toLocaleString()}</span> );
        }
    else{
        toReturn = ( <span>Not available</span> );
    }


    return(
        toReturn 
    )
}

const AssetTimeALR = props => {
    let update = new Date(props.time);
    let prevDate = update.getTime();
  
    let current = new Date();
    let curDate = current.getTime();

    let toReturn = "";

    let style = "";

    if(props.time !== null){
        let evaltime = parseInt((curDate - prevDate) / (1000 * 3600 * 24))

        if(evaltime >= 3){
            toReturn = ( <span className = 'red_alert'>{new Date(props.time).toLocaleString()}</span> );
        }
        else{
            toReturn = ( <span>{new Date(props.time).toLocaleString()}</span> );
        }
    }
    else{
        toReturn = ( <span>Not available</span> );
    }


    return(
        toReturn 
    )
}

const Battery = props =>
  props.battery ? <span>{(props.battery / 1000).toFixed(2)}v</span> : <span>Not available</span>;

const DaysOut = props => {
    let update = new Date(props.asset.job_shipped);
    let prevDate = update.getTime();
  
    let current = new Date();
    let curDate = current.getTime();
  
    let evaltime = 0
  
    if(props.asset.job_shipped !== null && props.asset.job_end === null){
      evaltime = parseInt((curDate - prevDate) / (1000 * 3600 * 24))
    }

    return(
        <span>{evaltime} days</span>
    )

    //props.battery ? <span>{(props.battery / 1000).toFixed(2)}v</span> : <span>Not available</span>;

    }



export default GoogleApiWrapper({
  apiKey: (key)
})(Home)

