/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b8878179-85ef-4db7-b7e7-20a733ad5286",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_cXYKE72DY",
    "aws_user_pools_web_client_id": "64b13e6i352v6fitap9etoptmk",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "mats",
            "endpoint": "https://qfyx0o3nsa.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
