import React,{Component} from 'react';
import "./Users.css";

export default class Users extends Component { 

render(){
    return(
        <div className="page-pad">
            <h2>Users</h2>
            <p>Coming in v2.11</p>
        </div>
    )};
}