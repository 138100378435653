import React,{Component} from 'react';
import "./NotFound.css";

export default class NotFound extends Component { 

render(){
    return(
        <div className="NotFound">
            <h3>Sorry, page not found!</h3>
        </div>
    )};
}
